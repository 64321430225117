:root {
  --primary-color: #00aeef;
  --secondary-color: #ffffff;
  --primary-bg-color: #f2fafc;
  --high-light-color: #f36f2c;
  --sub-high-light-color: #b2d700;
  --main-bg-color: #e7f5f7;
  --blue-bg-color: #f2fafc;
  /** Text Color*/
  --primary-text-color: #555859;
  --secondary-text-color: #6d6d6d;
  --sub-text-color: #aaaaaa;
  --white-text-color: #ffffff;
  --high-light-text-color: #00aeef;
  --sub-high-light-text-color: #b2d700;
  --error-text-color: #ff4646;
  --sub-bg-color: #ccf5f4;
  --black-color: #121212;
  --border-color: #d8d8d8;
  --overlay-bg-color: rgba(0, 174, 239, 0.1);
}
