.listItem {
  width: calc(100% - 48px);
  padding: 0 24px;
  overflow: hidden;

  :global(.adm-page-indicator-dot-active) {
    background: var(--black);
  }

  :global(.adm-swiper-item) {
    .inner {
      margin-right: 24px !important;
    }
  }
}
