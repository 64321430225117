.wrapper {
  width: auto;
  background: var(--primary-color);
  border-radius: 20px;
  margin: 13px 25px 0 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .content {
    margin: 64px 6px 32px 6px;

    .typein {
      margin-bottom: 100px;
      p {
        color: var(--secondary-color);
        margin-bottom: 28px;
      }

      .textarea {
        width: 273px;
        height: 235px;
        margin: 0 27px 0 27px;
        border-radius: 20px;
        border: none;
        font-size: 16px;
        background: var(--secondary-color);
        color: var(--primary-text-color);
        resize: none;
        line-height: 2;
        box-sizing: border-box;
        padding: 20px;
      }

      .textarea::placeholder {
        color: var(--primary-text-color);
        line-height: 2;
      }
    }
    .btnGroup {
      flex-direction: column;
      margin: 0 120px 0 120px;
    }
  }
}
