.modalBody {
  padding: 24px;
  border-radius: 20px;
  background: var(--white-text-color);
  min-width: 356px;
  min-height: 200px;
  box-sizing: border-box;
  position: relative;

  .closeIcon {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
  }
}
