.wrapper {
  display: flex;
  align-items: center;
  gap: 6px;

  .switch {
    height: 18px;
    width: 36px;

    :global(.adm-switch-checked) {
      --height: 0px;
    }

    :global(.adm-switch-checkbox) {
      height: 18px;
      min-width: 36px !important;
      border-radius: 31px !important;
      overflow: hidden;

      &::before {
        height: 18px;
        width: 36px;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 31px !important;
        overflow: hidden;
      }
    }

    :global(.adm-switch-handle) {
      height: 10px;
      width: 10px;
      background: transparent;
      border: 2px solid var(--primary-color);
    }

    :global(.adm-switch-inner) {
      margin: 0;
      background: #d9d9d9;
      border-radius: 31px !important;
      overflow: hidden;
    }
  }
}
