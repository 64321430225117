.wrapper {
  margin-top: 24px;

  .steps {
    margin-top: 24px;

    .stepIndex {
      font-size: var(--font-md);
      font-weight: var(--font-bold);
      color: var(--color-black);
      text-decoration: underline;
    }

    .imageStep {
      margin: 24px 0;
    }
  }
}
