.searchClub {
  position: relative;

  .searchWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;

    .search {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: var(--font-md);
      padding: 16px 24px;
      border: 1px solid var(--primary-text-color);
      border-radius: var(--space-large);
      background: rgba(85, 88, 89, 0.05);
      height: 52px;
      box-sizing: border-box;

      &.active {
        border: 1px solid var(--primary-color);
        background-color: white;
      }

      &.activeCity {
        background-color: white;

        .searchClubInput {
          width: 100%;

          p {
            color: var(--black-color) !important;
            font-weight: var(--font-medium);
            text-transform: uppercase;
          }
        }
      }

      .searchClubIcon {
        margin-right: 15px;

        svg {
          width: 24px;
          height: 24px;
          color: var(--primary-color);
        }
      }

      .searchClubInput {
        p {
          color: var(--primary-text-color);
        }
      }
    }

    .closeIcon {
      position: absolute;
      right: 24px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .searchClubResult {
    width: 100%;
    display: none;
    background-color: var(--white-text-color);
    border-radius: var(--space-small);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;

    &.show {
      transform: translateY(0);
      display: block;
      position: absolute;
      z-index: 100;
    }

    .searchClubList {
      max-height: 300px;
      overflow: auto;
      padding: 12px 0;

      // &::-webkit-scrollbar {
      //   display: inline !important;
      // }

      .searchClubItem {
        font-size: var(--font-sm);
        font-weight: var(--font-light);
        align-self: center;
        text-align: center;
        text-transform: uppercase;
        padding: 8px;
        cursor: pointer;

        &:hover {
          // background-color: var(--primary-color);
          // color: var(--white-text-color);

          &:nth-child(1) {
            border-top-left-radius: var(--radius-small);
            border-top-right-radius: var(--radius-small);
          }

          &:nth-last-child(1) {
            border-bottom-left-radius: var(--radius-small);
            border-bottom-right-radius: var(--radius-small);
          }
        }
      }
    }
  }
}
