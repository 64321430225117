.cardWrapper {
  .mainCard {
    position: relative;
    margin-top: 12px;
    height: 56px;
    border-radius: 10px;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 12px;
    background: var(--white-text-color);
    border: 1px solid var(--primary-text-color);

    .icon {
      width: 25px;
      height: 25px;
    }

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 5px;
      width: calc(100% - 55px);
      overflow: hidden;

      p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .indicator {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: var(--high-light-color);
      position: absolute;
      right: 12px;
    }
  }
}
