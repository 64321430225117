.cardWrapper {
  border-radius: 20px;
  box-shadow: 0px 4px 17.1px 0px rgba(0, 0, 0, 0.1);
  background: var(--white-text-color);
  padding: 12px 24px;
  text-align: center;

  .information {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;

    .rangeTime {
      margin-top: 12px;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;

      .rangeItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
      }
    }
  }
}
