.container {
  background: var(--primary-color);
  width: 100vw;
  height: calc(var(--app-height) - 50px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 250px;
  gap: var(--space-large);

  .logo {
    position: fixed;
    top: 150px;
  }

  .inputWrapper {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--space-small);
  }

  .btnGroup {
    display: flex;
    align-items: center;
    margin-top: 50px;
    width: 300px;
    flex-direction: column;
    gap: var(--space-medium);
  }
}
