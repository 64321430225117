.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(var(--app-height) - var(--header-height));

  .form {
    display: block;
    padding: 0 24px;
    width: calc(100% - 48px);

    .input {
      margin-top: 24px;
    }
  }

  .resendCode {
    padding: 0 24px;
    position: fixed;
    bottom: 24px;
    text-align: left;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
