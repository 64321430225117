.border {
  border-radius: 32px;
  background: linear-gradient(to right, #00aeef, #00ebcd);
  padding: 2px;

  .borderWrapper {
    border-radius: 30px;
    overflow: hidden;

    .cardWrapper {
      border-radius: 28px;

      background: var(--white-text-color);
      padding: 12px 24px 24px 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .icon {
        display: flex;
        align-items: center;
        gap: 12px;
        height: 25px;

        svg {
          width: 25px;
          height: 25px;
        }
      }

      .information {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .contract {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
