.container {
  padding: 12px 24px 0 24px;

  .classes {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 24px;

    .class {
      padding-bottom: 18px;
      border-bottom: 1px solid var(--border-color);

      &:last-child {
        border: none;
      }
    }
  }
}
