.wrapper {
  padding: 12px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .controller {
    max-width: 300px;
    margin-top: 21px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    gap: 16px;
  }
}
