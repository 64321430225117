.cardWrapperSkeleton {
  padding: 24px;
  border-radius: 12px !important;
  height: 578px;
  box-sizing: border-box;
}

.cardWrapper {
  padding: 20px;
  border-radius: 20px;

  .cardHeader {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: space-between;

    .planName {
      font-size: 50px;
      min-height: 51px;
      font-weight: var(--font-medium);
      font-family: 'Bebas';
      color: var(--white-text-color);
      line-height: 45px;

      @media only screen and (max-width: 390px) {
        font-size: 45px;
      }
    }

    .tag {
      font-size: 22px;
      padding: 8px 20px;
      white-space: nowrap;
      vertical-align: middle;
      font-weight: var(--font-light);
      background: var(--sub-high-light-color);
      border-radius: 9px;
      box-sizing: border-box;
      font-family: 'Bebas';
      line-height: 22px;
    }
  }

  .cardPrice {
    margin-top: 16px;

    .price {
      position: relative;

      .value {
        font-size: 40px;
        color: var(--sub-high-light-color);
        font-weight: var(--font-medium);
        font-family: 'Bebas';
        margin-bottom: 8px;

        .duration {
          font-size: var(--font-sm);
          color: var(--white-text-color);
          font-weight: var(--font-light);
          font-family: 'Gotham';
          transform: translateY(3px);
          display: inline-block;
        }
      }

      .originalValue {
        position: absolute;
        color: var(--white-text-color);
        font-family: 'Bebas';
        font-size: 24px;
        font-style: normal;
        line-height: normal;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        top: -18px;
        text-underline-offset: -8px;
      }
    }
  }

  .cardInfo {
    .commitment {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 16px 0;
      font-weight: var(--font-light);
    }

    .mainInfo {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .infoItem {
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;

        p {
          font-size: var(--font-md);
          color: var(--white-text-color);
          font-weight: var(--font-light);
          line-height: normal;
          padding-right: 24px;
        }

        .tooltip {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .extendInfo {
      overflow: hidden;
      transition: 0.3s linear;

      .listItem {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .item {
          display: flex;
          align-items: center;
          gap: 8px;
          line-height: normal;
          flex-shrink: 1;
        }
      }
    }
  }

  .controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    .moreDetail {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }
}
