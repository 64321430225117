.listItem {
  overflow: visible !important;

  &.isBlackCard {
    :global(.adm-page-indicator-dot) {
      border: 2px solid var(--white-text-color);
    }
  }

  :global(.adm-page-indicator-dot) {
    border: 2px solid var(--primary-color);
  }

  :global(.adm-swiper-indicator) {
    bottom: 0;
  }
}
