.cardWrapper {
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  padding: 12px 0;
  border-bottom: 1px solid var(--primary-text-color);

  .iconCard {
    width: 41px;
    height: 28px;
    object-fit: scale-down;
  }

  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .indicator {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--high-light-color);
  }
}
