.wrapper {
  padding: 0 24px;

  .listWrapper {
    height: calc(var(--app-height) - 100px);
    overflow: auto;

    .notifyList {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-bottom: 24px;
    }
  }
}
