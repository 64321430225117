.cardWrapper {
  padding: 18px 24px;
  background: var(--white-text-color);
  border-radius: var(--radius-extra-small);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--space-ultra-small);
  position: relative;
  height: 40px;
  border-radius: 20px;
  border: 1px solid var(--primary-text-color);

  .button {
    position: absolute;
    right: var(--space-medium);
    top: 50%;
    transform: translateY(-50%);
    width: 130px;
  }
}
