.cardWrapper {
  max-width: 250px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 12px 16px;
  background: var(--white-text-color);
  border: 1px solid var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .topInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottomInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 3px;
  }
}
