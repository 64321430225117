.cardWrapper {
  box-sizing: border-box;
  background: var(--white-text-color);
  padding: var(--space-medium);
  border-radius: var(--radius-small);
  border: 1px solid var(--primary-text-color);

  .cardClubTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 16px;

    .cardClubName {
      font-size: var(--font-lg);
      font-weight: var(--font-bold);
      color: var(--black-color);
    }

    .cardClubDistant {
      font-size: var(--font-sm);
      font-weight: 300;
      color: var(--primary-color);
    }
  }

  .cardClubLocate {
    display: flex;
    align-items: flex-start;
    gap: 5px;

    .cardClubIcon {
      width: 15px;
      height: 15px;
    }

    .cardClubAddress {
      font-size: var(--font-sm);
      font-weight: 300;
      color: var(--primary-text-color);
    }
  }

  .cardClubLine {
    width: 100%;
    margin: 19px auto 16px;
    border-color: var(--primary-text-color);
  }

  .cardClubAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--space-small);
    font-size: var(--font-md);

    .cardClubDetailButton {
      color: var(--primary-color);
      text-decoration: underline;
      font-weight: 450;
    }

    .cardClubReviewPlanButton {
      color: var(--white-text-color);
      background-color: var(--primary-color);
      padding: var(--space-small) var(--space-medium);
      border-radius: var(--radius-large);
      border: none;
      cursor: pointer;
    }
  }
}
