.panel {
  z-index: 1000;
  position: fixed;

  :global(.adm-floating-panel-header) {
    background-color: var(--white-text-color);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    height: 32px;
    box-shadow: var(--shadow-03);
  }

  :global(.adm-floating-panel-bar) {
    background: var(--sub-text-color) !important;
    height: 4px !important;
    width: 24px !important;
  }

  :global(.adm-floating-panel-content) {
    padding: 0px 24px;
    background-color: var(--white-text-color);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: var(--space-small);
    padding-top: var(--space-small);

    .labelGroup {
      display: flex;
      padding: 0 20px;
      width: 100%;

      p {
        width: 50%;
        text-align: center;
      }
    }
  }

  :global(.adm-picker-view) {
    height: calc(100% - 140px);
  }

  :global(.adm-picker-view-column-item-label) {
    font-size: var(--font-xl);
    color: var(--primary-text-color);
  }
}
