.container {
  height: 100%;
  position: relative;

  .header {
    position: fixed;
    width: 100%;
    top: 45px;
    height: 61px;
    z-index: 10;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: var(--space-small);
    position: relative;
    width: calc(100% - 24px * 2);
    top: 61px;
    height: calc(100% - 32px);
    overflow: auto;
    padding: 0 24px;
  }
}
