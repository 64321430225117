.tabWrapper {
  color: var(--white-text-color);

  :global(.adm-capsule-tabs-header) {
    border: none !important;
    padding-top: 0;
    padding-bottom: 0;
  }

  :global(.adm-capsule-tabs-list) {
    overflow: hidden;
  }

  :global(.adm-capsule-tabs-wrapper) {
    padding: 0;
  }

  :global(.adm-capsule-tabs-line) {
    background: var(--high-light-color);
    height: 4px;
  }

  :global(.adm-capsule-tabs-tab-active) {
    color: var(--white-text-color) !important;
    background: var(--primary-color) !important;
  }

  :global(.adm-capsule-tabs-tab) {
    background: var(--white-text-color);
    color: var(--primary-text-color);
    border: 1px solid var(--primary-color);
    font-weight: var(--font-medium);
    height: 34px;
    box-sizing: border-box;
    font-size: var(--font-md);
    padding: 0px 8px;
    line-height: 32px;
  }

  :global(.adm-capsule-tabs) {
    font-size: var(--font-md);
    margin: 0;
    width: 100%;
    text-align: center;
  }

  :global(.adm-tabs-header-mask) {
    display: none;
    box-shadow: none !important;
    border: none !important;
  }

  :global(.adm-capsule-tabs-tab-wrapper) {
    min-width: fit-content;
  }
}
