.wrapper {
  padding-top: 32px;
  width: 100%;
  overflow: hidden;

  .content {
    padding-top: 18px;
    background-color: var(--white-text-color);
    position: relative;
    height: calc(var(--app-height) - 95px);
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: fixed;

    .header {
      position: relative;
      height: var(--header-height);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        text-align: center;
      }

      .backIcon {
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .body {
      height: calc(var(--app-height) - 150px);
      width: calc(100% - 48px);
      padding: 0 24px;
      overflow-y: auto;
      overflow-x: hidden;

      .backgroundIcon {
        opacity: 0.2;
        position: absolute;
        right: -20px;
        top: 100px;
      }

      .title {
        font-size: 32px;
        font-weight: bold;
        color: var(--primary-color);
        text-transform: uppercase;
      }

      .notifyContent {
        margin-top: 25px;
        margin-bottom: 30px;
      }
    }
  }
}
