.border {
  border-radius: 46px;
  background: linear-gradient(to right, #00aeef, #00ebcd);
  padding: 2px;
  width: 100%;

  .borderWrapper {
    border-radius: 46px;
    width: 100%;

    .selectorWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      background: var(--white-text-color);
      border-radius: 46px;
      padding: 9px 24px;
      height: 22px;

      .blockContent {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        position: relative;

        p {
          width: calc(100% - 20px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-bottom: 2px;
          position: relative;
          top: 2px;
        }

        .iconFront {
          min-width: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(90deg);
          right: 0;
        }
      }
    }
  }
}
