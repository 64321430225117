.wrapper {
  display: flex;
  gap: 12px;

  .itemSkeleton {
    cursor: pointer;
    width: 103px;
    height: 80px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    gap: 3px;
    border: 3px solid var(--primary-color);
  }

  .item {
    cursor: pointer;
    width: 103px;
    height: 80px;
    border-radius: 30px;
    background: var(--white-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    gap: 3px;
    border: 3px solid var(--primary-color);
    transition: 0.2s;

    &.active {
      background: var(--primary-color);

      p {
        color: var(--white-text-color);
      }

      svg {
        path {
          stroke: var(--white-text-color);
        }
      }
    }
  }
}
