.planBlock {
  background: var(--white-text-color);
  border-radius: var(--radius-extra-small);
  padding: var(--space-small) var(--space-medium);

  .blockItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-small);

    .icon {
      flex: 1;
    }

    .input {
      input {
        font-size: var(--font-sm);
        font-weight: var(--font-bold);
        caret-color: var(--primary-text-color);
        color: var(--primary-color);
      }
    }
  }
}
