.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-large);
  height: calc(var(--app-height) - var(--header-height));

  .information {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-small);
    margin-top: var(--space-medium);

    .avatar {
      width: 110px;
      height: 110px;
      border-radius: 110px;
    }
  }

  .content {
    padding: 0 24px;

    .wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12px;

      .formItem {
        width: 45%;
      }
    }

    .formItem {
      padding: 12px 0;
      display: flex;
      flex-direction: column;
      gap: 6px;

      .phoneNumberGroup {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .button {
          padding: 0;
          border: none;
          background-color: transparent;
          font-size: 14px;
          color: var(--primary-color);
          font-weight: bold;
        }
      }

      input {
        font-size: var(--font-sm);
        color: var(--color-black);
      }
    }

    .divider {
      margin: 0;
      border-color: #d8d8d8;
    }
  }

  .avatarEditor {
    :global(.adm-mask) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
