.wrapper {
  padding: 0 var(--space-small);

  .container {
    height: 400px;
    margin-top: 40px;
    padding: 24px;
    border-radius: 30px;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .iconWrapper {
      padding: 1px;
      border-radius: 70px;
      background: var(--white-text-color);
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .bottomButton {
    position: fixed;
    width: calc(100% - 48px);
    bottom: 24px;
    margin: 0 24px;
    display: block;
    left: 0;
  }
}
