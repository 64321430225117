.cardWrapper {
  padding: var(--space-medium) var(--space-small) var(--space-medium) var(--space-medium);
  border-radius: var(--radius-extra-small);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  position: relative;
  transition: 0.1s;

  &::after {
    content: '';
    width: 25px;
    height: 25px;
    background-color: var(--white-text-color);
    position: absolute;
    right: 33px;
    border-radius: 25px;
    bottom: -18px;
  }

  &::before {
    content: '';
    width: 25px;
    height: 25px;
    background-color: var(--white-text-color);
    position: absolute;
    right: 33px;
    border-radius: 25px;
    top: -18px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--space-ultra-small);
  }
}
