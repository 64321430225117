.wrapper {
  height: calc(var(--app-height) - var(--header-height) - var(--header-slogan-height));
  width: 100%;
  position: fixed;

  .header {
    width: 100%;
    height: 61px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .content {
    margin-top: 10px;
  }
}
