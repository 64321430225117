.wrapper {
  width: 100%;

  :global(.adm-list-item) {
    padding-left: 0;
    background: transparent;
  }

  :global(.adm-list-item-content) {
    padding-right: 0;
    border: none;
  }

  :global(.adm-list-body) {
    background: transparent;
    border: none;
  }

  .title {
    :global(.adm-list-item-content-main) {
      padding-bottom: var(--space-ultra-small);
      padding-top: 0;
    }
  }

  .input {
    background-color: var(--white-text-color);
    height: 50px;
    padding: 0 var(--space-small);
    border-radius: 50px;

    input {
      color: var(--primary-text-color);
      font-size: var(--font-xl);
      text-align: center;
      caret-color: var(--primary-text-color);
    }
  }

  .errorMessage {
    :global(.adm-list-item-content-main) {
      padding: 0;
    }
  }
}
