:root {
  @font-face {
    font-family: 'Gotham';
    src: url('../font/SVN-Gotham Black.otf');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('../font/SVN-Gotham Bold.otf');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('../font/SVN-Gotham Regular.otf');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('../font/SVN-Gotham Book.otf');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('../font/SVN-Gotham Light.otf');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bebas';
    src: url('../font/UTM-Bebas.ttf');
    font-weight: 300;
    font-style: normal;
  }
}
