.cardWrapper {
  width: 100%;
  position: relative;

  .blockContent {
    height: 98px;
    box-sizing: border-box;
    background: var(--white-text-color);
    padding: 24px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--Grey-TNG, #555859);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    gap: 6px;

    .info {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: calc(100% - 126px);
    }

    button {
      width: 120px;
    }
  }
}
