.modal {
  :global(.adm-center-popup-wrap) {
    max-width: 375px;
    width: calc(100% - 48px);
    padding: 0 24px;
  }

  :global(.adm-modal-body) {
    border-radius: 28px;
    overflow: hidden;
    padding-top: 32px;
  }

  :global(.adm-modal-header) {
    padding: 0 24px;
    margin-bottom: 22px;
    position: relative;

    .closeIcon {
      position: absolute;
      top: -13px;
      right: 24px;
    }
  }

  :global(.adm-modal-content) {
    padding: 0 24px;
    margin-bottom: 22px;
  }

  :global(.adm-modal-title) {
    font-size: var(--font-md) !important;
  }

  :global(.adm-button) {
    span {
      font-size: var(--font-md) !important;
    }
  }

  :global(.adm-modal-footer) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 0 24px 24px 24px;
  }

  :global(.adm-space-item) {
    width: calc((100% / 2) - 8px);
    margin: 0;

    button {
      height: 33px;
      box-sizing: border-box;
    }
  }

  :global(.adm-modal-button-primary) {
    background: var(--primary-color) !important;
    color: var(--white-text-color) !important;
    border: none !important;
    border-radius: 30px;
    font-size: var(--font-sm);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global(.adm-modal-button) {
    background: var(--white-01);
    color: var(--primary-color);
    border: none !important;
    font-size: var(--font-md);
    font-weight: var(--font-bold);
    padding: 8px 16px;
    border-radius: 30px;
  }
}
