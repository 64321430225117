.customTimeline {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 3px;
    height: calc(100% - 175px);
    background: linear-gradient(180deg, rgba(0, 174, 239, 1) 0%, rgba(180, 233, 245, 0.8) 100%);
    top: calc(50% - 24px);
    transform: translateY(-50%);
    left: 3.5px;
  }

  :global(.ant-timeline-item) {
    padding-bottom: 40px;
  }

  :global(.ant-timeline-item-content) {
    inset-block-start: 0;
    margin-inline-start: 22px;
  }

  :global(.ant-timeline-item-tail) {
    border-inline-start: 3px solid;
    inset-inline-start: 3.5px;
    top: calc(50% - 20px);
    height: 100%;
    border-color: transparent;
  }

  :global(.ant-timeline-item-head) {
    padding: 0;
    top: calc(50% - 20px);
    background: transparent;
  }

  .indicator {
    width: 13px;
    height: 13px;
    border-radius: 13px;

    &.future {
      background: var(--primary-color);
      box-shadow: 0px 4px 4px 0px rgba(57, 191, 187, 0.38) inset;
      filter: drop-shadow(0px 0px 2px rgba(0, 100, 194, 0.4));
    }

    &.active {
      background: #b2d700;
      box-shadow: 0px 4px 4px 0px rgba(57, 191, 176, 0.38) inset;
      filter: drop-shadow(0px 0px 2px rgba(74, 194, 0, 0.4));
    }

    &.expired {
      background: #f36f2c;
      box-shadow: 0px 4px 4px 0px rgba(191, 93, 57, 0.38) inset;
      filter: drop-shadow(0px 0px 2px rgba(194, 133, 0, 0.4));
    }
  }
}
