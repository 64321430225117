.wrapper {
  padding: 0 24px;
  padding-bottom: 0;

  .selectClub {
    padding-top: 16px;
  }

  .date {
    margin-top: 12px;
    width: 100%;
  }

  .content {
    margin-top: 20px;

    .listClass {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 18px;
      height: calc(var(--app-height) - var(--header-height) - var(--header-slogan-height) - 182px);
      overflow: auto;
    }
  }
}
