.container {
  width: 100%;
  overflow: auto;
  position: relative;

  .slogan {
    position: sticky;
    top: 0;
    background: var(--primary-color);
    color: var(--white-text-color);
    text-align: center;
    padding: 10px 24px;
    font-size: var(--font-lg);
    font-style: italic;
    text-transform: uppercase;
    text-align: center;
    font-weight: var(--font-bold);
    z-index: 100;
  }

  .body {
    padding: 0 24px;
    height: var(--app-height);
  }

  .warning {
    width: 100vw;
    height: var(--app-height);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    p {
      text-align: center;
    }
  }
}
