.border {
  border-radius: 46px;
  background: linear-gradient(to right, #00aeef, #00ebcd);
  padding: 2px;
  width: 100%;

  .borderWrapper {
    border-radius: 46px;
    width: 100%;

    .selectorWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      position: relative;
      background: var(--white-text-color);
      border-radius: 46px;
      padding: 9px 24px;

      .blockContent {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        position: relative;

        .iconFront {
          min-width: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(90deg);
          right: 0;
        }
      }

      .panelBody {
        display: flex;
        flex-direction: column;
        gap: var(--space-small);

        .note {
          text-align: center;
          width: auto;
          padding: 6px 24px;
          background: var(--white-text-color);
          border-radius: var(--radius-extra-small);
        }

        .listPromotion {
          margin-top: var(--space-small);
          display: flex;
          flex-direction: column;
          gap: var(--space-small);
        }
      }
    }
  }
}
