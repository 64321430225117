.wrapper {
  p,
  span {
    font-size: 16px;
    font-weight: var(--font-light);
    color: var(--primary-text-color);
    white-space: pre-wrap;
  }

  .textBold {
    font-weight: var(--font-bold);
  }

  .textUppercase {
    text-transform: uppercase;
  }

  .haveSelect {
    user-select: all !important;
    text-decoration: underline;
  }

  .block {
    margin-top: 16px;
  }
}
