.wrapper {
  padding: var(--space-medium);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-medium);
  position: relative;
  padding-top: 48px;

  .bottomButton {
    position: fixed;
    width: calc(100vw - 48px);
    bottom: var(--space-medium);
    margin: 0 var(--space-medium);
  }
}
