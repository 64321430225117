.footerWrapper {
  height: 80px;
  box-sizing: border-box;
  background: var(--white-text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 12px 22px 12px;
  position: fixed;
  z-index: 100;
  width: calc(100%);
  bottom: 0;
  gap: var(--space-medium);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .item {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100px;
    max-width: 70px;
    gap: var(--space-ultra-small);

    .indicator {
      width: 26px;
      height: 5px;
      background-color: var(--primary-color);
      position: absolute;
      top: -24px;
      border-radius: 2px;
    }

    p {
      transition: 0.1s;
    }
  }
}
