.headerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: var(--space-small);
  padding-top: var(--space-small);
  background: var(--primary-color);
  overflow: hidden;
  z-index: 1000;
  min-height: 30px;

  .title {
    width: calc(100% - 100px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      text-align: center;
    }
  }

  .iconWhite {
    svg {
      width: 100%;
      height: 100%;
    }

    svg path {
      stroke: var(--white-text-color);
    }
  }

  .iconBlue {
    svg {
      width: 100%;
      height: 100%;
    }

    svg path {
      stroke: var(--primary-color);
    }
  }
}
