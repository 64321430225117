.wrapper {
  padding: 0 var(--space-small);

  .container {
    height: 400px;
    margin-top: 40px;
    padding: var(--space-medium);
    border-radius: 30px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    background: var(--white-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--space-extra-large);
  }

  .bottomButton {
    position: fixed;
    width: calc(100% - 24px);
    bottom: var(--space-medium);
  }
}
