.wrapper {
  padding: 0 var(--space-medium);
  display: flex;
  flex-direction: column;
  gap: var(--space-medium);
  padding-top: var(--space-medium);

  .item {
    width: 100%;
    height: 200px;
    border-radius: 32px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    background-repeat: no-repeat;
    box-sizing: border-box;

    .content {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 100%;
      background-color: rgba(18, 18, 18, 0.3);

      p {
        padding-bottom: 30px;
      }
    }
  }
}
