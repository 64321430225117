.wrapper {
  position: relative;

  .clubWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    background: var(--overlay-bg-color);
  }

  .info {
    padding: 10px 24px 0 24px;

    .promoCode {
      padding: 12px 0;
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
    }

    .promotionInput {
      margin: 20px auto 10px auto;
    }

    .promotionWrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .tabs {
    position: sticky;
    top: -1px;
    padding: 16px 12px;
    z-index: 100;
    background-color: var(--blue-bg-color);

    .customTab {
      :global(.adm-capsule-tabs-tab) {
        font-size: var(--font-sm);
        text-transform: uppercase;
      }
    }
  }

  .plan {
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: flex-start;
    justify-content: center;
    margin: 0;

    .item {
      width: 100%;
    }
  }

  .comparePlan {
    margin-top: 24px;
  }

  .faqs {
    margin-top: 24px;
  }
}
