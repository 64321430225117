.container {
  width: 100vw;
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  gap: var(--space-large);

  .logo {
    margin-top: 42px;
  }

  .title {
    text-align: center;
    position: fixed;
    top: 40%;
    transform: translateY(-50%);

    p {
      font-size: 48px;
      text-transform: uppercase;
      font-weight: 900;
      font-style: italic;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #555859;
      transform: rotate(-7.093deg);
      text-shadow: 2px 4px #555859;
      white-space: pre-wrap;
      text-align: center;
    }
  }

  .content {
    position: fixed;
    bottom: 24px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 48px);
  }

  .btnGroup {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }
}
