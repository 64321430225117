.container {
  .backGround {
    position: relative;
    top: 0;
    height: 400px;
    width: 100%;
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    background-position-x: 50%;

    .backgroundOverlay {
      background: rgba(18, 18, 18, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;

      .hightLightTitle {
        color: var(--primary-color);
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: var(--white, #fff);
        font-size: 27px;
        font-weight: var(--font-bold);
        line-height: normal;
        letter-spacing: 0.66px;
        text-transform: uppercase;
        white-space: pre-wrap;
      }
    }

    .icon {
      padding: 28px;
      background: var(--primary-color);
      border-radius: 100px;
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
  }

  .information {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 24px;

    p {
      text-align: center;
      line-height: normal;
    }
  }

  .buttonGroup {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    gap: 13px;
    align-items: center;
    justify-content: center;
  }
}
