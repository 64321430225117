.cardWrapper {
  width: 100%;
  height: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 16px;

  .thumbnail {
    width: 170px;
    height: 90px;
    min-width: 170px;
    min-height: 90px;
    border-radius: 10px;
    overflow: hidden;

    :global(.adm-image) {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    width: 100%;
    height: 100%;
  }
}
