.wrapper {
  .chipList {
    margin-left: -24px;
    width: 100vw;

    .chipListWrapper {
      border: none;
      transition: 0.3s;

      :global(.adm-capsule-tabs-header) {
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        padding: 0;
      }

      :global(.adm-scroll-mask-left) {
        background: linear-gradient(to right, var(--primary-bg-color), rgba(255, 255, 255, 0));
        width: 120px;
      }

      :global(.adm-scroll-mask-right) {
        background: linear-gradient(to left, var(--primary-bg-color), rgba(255, 255, 255, 0));
        width: 100px;
      }

      .chipItem {
        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }

        :global(.adm-capsule-tabs-tab) {
          font-size: var(--font-xs) !important;
          border-radius: 20px;
          padding: 8px 12px;
          border: 1px solid var(--primary-color);
          color: var(--primary-color);
          font-weight: var(--font-medium);
          transition: 0.2s;
        }

        :global(.adm-capsule-tabs-tab-active) {
          color: var(--white-text-color) !important;
          background: var(--primary-color) !important;
        }

        :global(.adm-capsule-tabs-tab) {
          background: var(--white-text-color);
          color: var(--primary-text-color);
          border: 1px solid var(--primary-color);
        }
      }
    }
  }
}
