.containerWrapper {
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .container {
    padding-bottom: 64px;
    background: var(--blue-bg-color);
    padding-top: 7px;
    height: 100%;

    &.blackCard {
      background: linear-gradient(
        180deg,
        rgba(18, 18, 18, 1) 0%,
        rgba(0, 0, 0, 0.1) 10%,
        rgba(0, 0, 0, 0.1) 80%,
        rgba(18, 18, 18, 1) 97%
      ) !important;
    }

    .cardSwiper {
      width: 100%;
      overflow: hidden;
      padding-top: 12px;
    }

    .content {
      padding: 0 var(--space-large);
    }

    .note {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-top: 37px;
      padding-bottom: 32px;

      p {
        white-space: wrap;
        text-align: center;
      }
    }
  }
}
