@import './font-size';
@import './font-weight';
@import './color';
@import './classname';
@import './reset';
@import './overwrite';
@import './box-shadow';
@import './font-family';
@import './space';
@import './border-radius';

:root {
  --header-height: 54px;
  --header-slogan-height: 45px;
}

html {
  overflow: visible;
  min-height: var(--app-height);

  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: var(--secondary-text-color);
  }

  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    padding-right: 12px;
    background-color: transparent;
  }

  * {
    line-height: normal;
  }

  p,
  div,
  span,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img {
    user-select: none;
  }
}

body {
  margin: 0 !important;
  font-family: 'Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background: var(--blue-bg-color);
  caret-color: var(--primary-text-color);

  .adm-image-img {
    object-fit: cover !important;
  }
}

.adm-switch-checked {
  .adm-switch-handle {
    background: var(--primary-color) !important;
  }
}

.rpv-core__page-layer {
  margin: auto;
}

.rpv-core__text-layer {
  display: none;
}

.adm-radio-icon {
  border-color: var(--secondary-text-color);
}

.adm-radio-checked {
  .adm-radio-icon {
    background-color: var(--sub-high-light-color) !important;
    border-color: var(--sub-high-light-color) !important;
  }
}

.adm-dialog-body {
  border-radius: 28px !important;

  .adm-dialog-button {
    color: var(--primary-color);
    font-weight: var(--font-medium);
  }
}

.adm-center-popup-wrap {
  max-width: 375px;
  width: calc(100% - 48px);
  padding: 0 24px;
}

.custom-link {
  text-decoration: none;
  color: var(--high-light-color);
}
