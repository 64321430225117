.wrapper {
  padding: 0 24px;

  .clubImage {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 20px;

    :global(.adm-image) {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .content {
    margin-top: 24px;

    .clubName {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .blockInfo {
      margin-top: 12px;

      .blockItem {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 14px;
        border-bottom: 1px solid var(--primary-text-color);
        padding-bottom: 12px;
        margin-bottom: 12px;

        .openDate {
          margin-top: 8px;

          p {
            line-height: 18px;
          }
        }

        .blockContent {
          width: 100%;
          padding-right: 6px;

          .clubPhone {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .whatYouGet {
    .benefitInfo {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .blockItem {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .controller {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px 24px 24px;
    position: fixed;
    bottom: 0;
    gap: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;

    button {
      white-space: nowrap;
    }
  }
}
