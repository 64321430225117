.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 54px;
  width: 100%;
  height: calc(var(--app-height) - var(--header-height));

  .information {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-small);
    margin-top: var(--space-medium);

    .avatarWrapper {
      width: 110px;
      height: 110px;
      border-radius: 110px;
      position: relative;

      .avatar {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 110px;
      }

      .emoji {
        position: absolute;
        bottom: -5px;
        right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        box-sizing: border-box;
        padding: 3px;
        width: 40px;
        height: 40px;

        background-color: var(--white-text-color);
        border-radius: 30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          border-radius: 40px;
        }
      }
    }
  }

  .controller {
    overflow: auto;
    background: var(--white-text-color);
    padding: var(--space-large) var(--space-medium) var(--space-medium) var(--space-medium);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    height: calc(var(--app-height) - 211px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--space-small);

        .icon {
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.customPanel {
  :global(.adm-floating-panel-content) {
    background: var(--sub-high-light-color);
  }

  :global(.adm-floating-panel-header) {
    background: var(--sub-high-light-color);
  }

  :global(.adm-floating-panel) {
    &::after {
      background: var(--sub-high-light-color);
    }
  }

  :global(.adm-floating-panel-bar) {
    background: #fafafa !important;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: var(--space-small);
    gap: var(--space-medium);

    .buttons {
      width: 100%;
      display: flex;
      align-items: center;
      gap: var(--space-large);

      button {
        width: 50%;
      }
    }
  }
}
