.inputCode {
  :global(.adm-passcode-input-cell) {
    width: 24px;
    height: 36px;
    border-radius: 8px;
    padding: 4px;
    box-sizing: content-box;
    border: 1px solid var(--primary-text-color) !important;
    background: transparent;
  }

  &.error {
    :global(.adm-passcode-input-cell) {
      border: 1px solid var(--error-text-color) !important;
    }
  }

  :global(.adm-passcode-input-cell-focused) {
    border: 1px solid var(--primary-color) !important;
  }

  :global(.adm-passcode-input-cell-caret) {
    &::after {
      background-color: var(--primary-color);
    }
  }
}
