.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .qrBlock {
    margin-top: 34px;
    padding: 24px;
    border-radius: 20px;

    .wrapperQr {
      width: 200px;
      height: 200px;
      border-radius: 18px;
    }
  }

  .extendInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--space-small);
    margin-top: 10px;

    .info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .membershipName {
      margin-top: 20px;
      color: var(--white-text-color);
      font-size: 36px;
      font-weight: var(--font-bold);
      text-align: center;
      line-height: 40px;
      white-space: pre-wrap;
      padding-bottom: 6px;
    }
  }
}
