.cardWrapper {
  border-radius: 18px;

  .image {
    height: 180px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  .moreInfo {
    padding: 12px 30px;
    background-color: rgba(0, 174, 239, 0.6);
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    display: flex;
    justify-content: space-between;
    gap: 6px;

    .block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      gap: 3px;
    }
  }
}
