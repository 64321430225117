.cardWrapper {
  padding: 24px 12px;
  border-radius: 12px;
  border: 1px solid rgba(85, 88, 89, 0.3);
  position: relative;
  box-shadow: 5px 7px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 25px 0;
  background: var(--white-text-color);

  .title {
    text-align: center;
    margin-top: 16px;

    .statusIcon {
      width: 50px;
      height: 50px;
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
      background: var(--white-text-color);
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      overflow: hidden;
    }
  }

  .transactionInfo {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 0px;

    &.open {
      // max-height: 600px;
      overflow: hidden;
      transition: 0.2s linear;
      margin-top: 32px;
      padding-top: 12px;
      border-top: 1px dashed var(--secondary-text-color);
    }

    .blockContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      padding: 6px 0;

      .blockLabel {
        color: var(--primary-text-color);
        font-weight: var(--font-light);
        font-size: var(--font-lg);
        white-space: pre-wrap;
      }

      .blockValue {
        color: var(--black-color);
        font-weight: var(--font-medium);
        font-size: var(--font-md);
        text-align: right;
      }

      .blockValueSmall {
        color: var(--primary-text-color);
        font-weight: var(--font-medium);
        font-size: 11px;
        text-align: right;
      }
    }
  }

  .toggleTag {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    background: var(--white-text-color);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -27px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .background {
      position: absolute;
      z-index: 1;
    }

    .arrow {
      z-index: 2;
    }
  }
}
