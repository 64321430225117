.wrapper {
  padding: 24px;
  background-color: #ddf1f6;

  .question {
    margin-top: 26px;

    :global(.adm-list-body) {
      border: none;
      background-color: #ddf1f6;
    }

    :global(.adm-list-item) {
      background-color: #ddf1f6;
      padding-left: 0;

      &:active {
        background-color: #ddf1f6;
      }
    }

    :global(.adm-list-item-content) {
      padding-right: 0;
      border-color: var(--secondary-text-color);

      &::after {
        border: none;
      }
    }

    :global(a.adm-list-item:active:not(.adm-list-item-disabled)::after) {
      display: none;
    }

    :global(.adm-list-item-content-main) {
      padding: 24px 0;
      font-size: var(--font-lg);
      font-weight: var(--font-medium);
      line-height: normal;
      color: var(--primary-text-color);
    }

    :global(.adm-collapse-panel-content-inner .adm-list-item-content) {
      border: none;
    }

    :global(.adm-collapse-panel-content-inner .adm-list-item-content .adm-list-item-content-main) {
      padding-top: 0;
      font-weight: var(--font-light);
    }
  }
}
