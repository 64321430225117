.inputWrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--secondary-text-color);
  border-radius: 8px;
  padding: 9px;
  background: var(--white-text-color);
  height: 40px;
  box-sizing: border-box;

  .international {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;

    svg {
      position: relative;
    }
  }

  input {
    font-size: var(--font-sm);
    color: var(--secondary-text-color);
    padding-top: 3px;
  }
}
