.container {
  padding: 0 24px;

  .headerContainer {
    padding: 16px 0;
  }

  .ptList {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 18px;
    overflow: auto;
    padding-top: 16px;
    height: calc(var(--app-height) - 200px);
  }
}
