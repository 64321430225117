.cardWrapper {
  border-radius: 32px;
  background: var(--white-text-color);
  padding: 12px 24px;
  text-align: center;
  border: 1px solid var(--primary-text-color);

  .icon {
    margin: auto;
    margin-bottom: 12px;
    text-align: center;
    width: 30px;
    height: 30px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .information {
    text-align: center;

    .rangeTime {
      margin-top: 12px;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;

      .rangeItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
      }
    }

    .rating {
      margin-top: 12px;
    }
  }

  .button {
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
