.containerWrapper {
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  height: var(--app-height);

  .container {
    height: 100%;
    background: var(--blue-bg-color);
    padding: 24px;

    &.blackCard {
      background: linear-gradient(
        180deg,
        rgba(18, 18, 18, 1) 0%,
        rgba(0, 0, 0, 0.1) 10%,
        rgba(0, 0, 0, 0.1) 80%,
        rgba(18, 18, 18, 1) 97%
      ) !important;
    }
  }
}
