.item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 6px 20px;
  gap: 20px;
  border-radius: 43px;
  border: 1px solid var(--primary-color);
  background: var(--secondary-color);
  box-sizing: border-box;
  margin-bottom: 12px;

  p {
    flex-grow: 1;
    display: flex;
  }

  .icon {
    display: flex;
  }

  path {
    margin: 2px;
  }
}
