.wrapperLayout {
  height: var(--app-height);
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: fixed;

  .slogan {
    font-size: var(--font-lg);
    font-style: italic;
    text-transform: uppercase;
    color: var(--primary-color);
    padding: 10px 24px;
    text-align: center;
    background: var(--white-text-color);
    font-weight: var(--font-bold);
    position: relative;
    z-index: 100;
  }

  :global(.adm-pull-to-refresh) {
    height: 100%;
  }

  :global(.adm-pull-to-refresh-content) {
    height: 100%;
  }

  .body {
    flex: 1;
    overflow: scroll;
    border: none;
    width: 100vw;
  }
}
