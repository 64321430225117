.panel {
  z-index: 1000;
  position: fixed;

  :global(.adm-floating-panel-header) {
    background-color: var(--white-text-color);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    height: 32px;
    box-shadow: var(--shadow-03);
  }

  :global(.adm-floating-panel-bar) {
    background: var(--sub-text-color) !important;
    height: 4px !important;
    width: 24px !important;
  }

  :global(.adm-floating-panel-content) {
    padding: 0px 24px;
    background-color: var(--white-text-color);
  }
}
