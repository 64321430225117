.container {
  position: relative;
  overflow: hidden;
  height: 100%;

  .header {
    width: 100%;
    height: 61px;
    z-index: 10;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: var(--space-small);
    position: relative;
    width: calc(100% - 24px * 2);
    height: calc(var(--app-height) - 188px);
    overflow: auto;
    padding: 0 24px;
  }
}
