.container {
  .controller {
    padding: 0 24px;
  }

  .historyList {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 24px;
  }

  .body {
    height: calc(var(--app-height) - 222px);
    overflow: auto;
    padding: 0 24px;

    .bookingContent {
      margin-top: var(--space-medium);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .history {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-bottom: 24px;
    }
  }
}
