.wrapperLayout {
  height: var(--app-height);
  width: 100vw;
  display: flex;
  position: fixed;
  flex-direction: column;

  .slogan {
    font-size: var(--font-lg);
    font-style: italic;
    text-transform: uppercase;
    color: var(--primary-color);
    padding: 10px 24px;
    text-align: center;
    background: var(--white-text-color);
    font-weight: var(--font-bold);
    position: relative;
    z-index: 100;
  }

  .body {
    overflow: scroll;
    height: var(--app-height);
    background: var(--blue-bg-color);
  }
}
