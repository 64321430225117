.cardWrapper {
  height: 150px;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;

  :global(.adm-image) {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}
