.wrapper {
  * {
    font-weight: var(--font-medium);
    font-size: var(--font-md);
    position: relative;
  }

  width: fit-content;
  height: 38px;
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;

  span {
    white-space: nowrap;
  }

  &.solid {
    color: var(--primary-color);
    border: none;
  }

  &.outline {
    color: var(--primary-color);
    background: transparent;
    border: 1px solid var(--primary-color);
  }

  &.outlineOrange {
    color: var(--high-light-color);
    background: transparent;
    border: 1px solid var(--high-light-color);
  }

  &.highLight {
    background: var(--high-light-color);
    color: var(--white-text-color);
    border: none;
  }

  &.highLightBlue {
    background: var(--primary-color);
    color: var(--white-text-color);
    border: none;
  }

  &.link {
    background: transparent;
    border: none;

    span {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }
}
