.wrapper {
  padding: 12px;

  .signatureBox {
    width: 100%;
    height: 250px;
    background: #d9d9d9;
    border-radius: 30px;
  }

  .controller {
    display: flex;
    gap: 12px;
    position: fixed;
    bottom: 24px;
    width: calc(100% - 24px);
  }
}
