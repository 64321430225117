.wrapper {
  padding: 0 24px;

  .transactionCard {
    max-width: 500px;
    margin: auto;
    margin-top: 32px;
  }

  .description {
    padding: 24px 0;
  }

  .discoverList {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media only screen and (min-width: 800px) {
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        width: calc(50% - 15px);
      }
    }
  }

  .controller {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 48px);
    padding: 12px 24px 24px 24px;
    position: fixed;
    bottom: 0;
    left: 0;
    gap: 12px;
    background: var(--white-text-color);

    @media only screen and (min-width: 800px) {
      width: fit-content;
      position: relative;
      bottom: 0;
      margin: auto;
      margin-top: 24px;
    }
  }
}
