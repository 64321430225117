.cardWrapper {
  height: 180px;
  width: 100%;
  border-radius: 20px;
  border: 2px solid var(--primary-color);
  background: var(--white-text-color);
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .iconCard {
    width: 130px;
    height: 40px;

    img {
      object-fit: scale-down !important;
    }
  }

  .cardAction {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
}
