.wrapper {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .wrapperItem {
    .listCard {
      margin-top: 12px;
    }

    .addCard {
      margin: 12px auto;
      display: block;
      background-color: transparent;

      span {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      border: none;
      padding: 0;
      font-size: var(--font-sm);
    }
  }
}
