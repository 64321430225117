.wrapper {
  border-radius: 20px;
  background: var(--white-text-color);
  padding: 30px;
  box-shadow: 0px 20px 40px 0px #0000001a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
