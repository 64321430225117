.avatarWrapper {
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: auto;
  position: relative;
  width: 110px;
  height: 110px;

  .icon {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    right: 50%;
    transform: translate((50%, -50%));
    opacity: 0.8;
  }

  .cropImage {
    position: fixed;
    background: white;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;

    .closeIcon {
      position: fixed;
      top: 68px;
      right: 12px;
      z-index: 11;
    }
  }
}
