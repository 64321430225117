.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  // background: transparent;
  overflow: hidden;
  z-index: 1000;
  width: calc(100% - var(--space-medium) * 2);
  height: 30px;
  padding: var(--space-small) var(--space-medium);
  transition: 0.3s;
  background: linear-gradient(180deg, rgba(242, 250, 252, 1) 1%, rgba(242, 250, 252, 0) 30%);

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    .icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      padding: 6px;
      border-radius: 34px;
      width: 34px;
      height: 34px;
      box-sizing: border-box;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      svg {
        width: 20px;
        height: 20px;
      }

      .notifyIndicator {
        top: 5px;
        right: 5px;
        width: 10px;
        height: 10px;
        position: absolute;
        border-radius: 10px;
        background: var(--high-light-color);
      }
    }

    .popup {
      display: flex;
    }
  }

  .shaking {
    animation: tilt-shaking 0.5s infinite;
  }

  @keyframes tilt-shaking {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(8deg);
    }

    50% {
      transform: rotate(0eg);
    }

    75% {
      transform: rotate(-8deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}
