.border {
  border-radius: 42px;
  background: linear-gradient(to right, #00aeef, #00ebcd);
  // background: var(--high-light-color);
  padding: 4px;
  width: fit-content;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);

  .borderWrapper {
    border-radius: 42px;
    width: fit-content;

    .wrapper {
      width: 220px;
      height: 290px;
      background: var(--white-text-color);
      border-radius: 40px;
      overflow: hidden;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;

      .hightLightTag {
        background: linear-gradient(to right, #00aeef, #00ebcd);
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
      }

      .price {
        margin-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .subContent {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .discount {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 6px;
        }
      }
    }
  }
}
