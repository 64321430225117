.border {
  border-radius: 46px;
  background: linear-gradient(to right, #00aeef, #00ebcd);
  padding: 2px;
  width: 100%;

  .borderWrapper {
    border-radius: 46px;
    width: 100%;

    .planBlock {
      background: var(--white-text-color);
      border-radius: var(--radius-extra-small);
      padding: 9px 24px;
      border-radius: 46px;

      .blockItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 6px;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 25px;
        }

        .input {
          input {
            font-size: var(--font-sm);
            font-weight: var(--font-medium);
            caret-color: var(--primary-text-color);
            color: var(--primary-color);
          }
        }
      }
    }
  }
}
