.cardWrapper {
  position: relative;
  margin-top: 70px;

  .image {
    position: absolute;
    top: -70px;
    right: 50%;
    transform: translateX(50%);
    width: 200px;
    height: 150px;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--primary-bg-color);
  }

  .content {
    background-color: var(--white-text-color);
    padding: 100px 24px 32px 24px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--primary-text-color);
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .bookingInfo {
      margin-top: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6px;

      .itemInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    button {
      width: 128px;
      margin-top: 24px;
    }
  }
}
