.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  height: calc(var(--app-height) - var(--header-height));
  width: calc(100% - 48px);
  position: relative;

  .form {
    width: 100%;

    .input {
      margin-top: 12px;
    }

    .controller {
      position: fixed;
      bottom: 24px;
      width: calc(100% - 48px);
    }
  }
}
