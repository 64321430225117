.selectorWrapper {
  border: 1px solid var(--white-text-color);
  padding: var(--space-ultra-small);
  padding-bottom: 8px;
  border-radius: var(--radius-extra-small);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-ultra-small);
  height: 20px;
  max-width: 200px;
  min-width: 100px;
}
