.wrapper {
  width: 100%;

  .signButton {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
  }

  .loadingWrapper {
    width: 100%;
    height: var(--app-height);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
