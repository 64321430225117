.wrapper {
  width: 100%;
  height: 48px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  background: var(--white-text-color);
  padding: 14px 20px;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  gap: 20px;
  position: relative;

  .icon {
    position: absolute;
    right: 20px;
  }
}
