.wrapper {
  width: 100%;
  padding: 13px 24px 0px 24px;
  box-sizing: border-box;
  border-radius: 10px;

  .header {
    flex: 1;
    margin-bottom: 33px;
    display: flex;
    align-items: flex-start;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }
}
