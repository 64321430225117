.wrapper {
  .selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--primary-color);
    border-radius: 12px;
    padding: 12px 24px;
    position: relative;

    .checkIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      background: var(--primary-color);
      position: absolute;
      top: -1px;
      left: -1px;
      border-top-left-radius: 12px;
      border-bottom-right-radius: 8.5px;
    }
  }

  .link {
    cursor: pointer;
    text-align: center;
    margin-top: 12px;
  }

  .itemCard {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 24px;
  }

  .panelBody {
    padding-bottom: 24px;

    .radioGroup {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .method {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--overlay-bg-color);
        border-radius: 12px;
        padding: 12px 24px;
        cursor: pointer;

        &.active {
          p {
            color: var(--white-text-color);
          }

          background: var(--primary-color);
        }

        .info {
          display: flex;
          align-items: center;
          gap: 8px;
          height: 24px;
        }

        .ratio {
        }
      }

      .debitCards {
        cursor: pointer;

        .listItem {
          margin-top: 12px;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px 12px 8px;
            border-bottom: 1px solid var(--secondary-text-color);
            margin-bottom: 12px;
            height: 28px;

            .leftInfo {
              display: flex;
              align-items: center;
              gap: 8px;

              .bankIcon {
                height: 28px;
                width: auto;
              }
            }

            .rightInfo {
              display: flex;
              align-items: center;
              gap: 4px;
              position: relative;
              padding-right: 28px;

              svg {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }

        .addCard {
          margin: auto;
          display: block;

          span {
            display: flex;
            align-items: center;
            gap: 8px;
          }

          border: none;
          padding: 0;
          font-size: var(--font-sm);
        }
      }
    }
  }
}
