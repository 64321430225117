.wrapper {
  .closeBlock {
    padding: 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    transition: 0.3s;

    .titleBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.3s;
      gap: 6px;

      @media only screen and (min-width: 800px) {
        gap: 12px;
        justify-content: center;
      }
    }
  }

  .compareTable {
    animation: growDown 0.2s ease-in-out forwards;
    transform-origin: top center;

    @keyframes growDown {
      0% {
        transform: scaleY(0);
      }

      80% {
        transform: scaleY(1.1);
      }

      100% {
        transform: scaleY(1);
      }
    }

    .blockInfo {
      display: flex;
      justify-content: space-around;
      margin: 16px auto;

      .card {
        padding: 16px;
        border-radius: 8px;
        border: 1px solid var(--primary-text-color);
        min-width: 117px;
        box-sizing: border-box;

        &.blackCard {
          background: linear-gradient(189deg, #00aeef 21.65%, #013641 105.54%);
          border: none;
          color: var(--white-text-color);
        }
      }
    }

    .block {
      display: flex;
      border-bottom: 1px solid var(--primary-text-color);

      &:nth-child(2n + 1) {
        background-color: var(--overlay-bg-color);
      }

      .left {
        width: 50%;
        border-right: 1px solid var(--primary-text-color);
        padding: 16px 10px 16px 24px;
        display: flex;
        align-items: center;
      }

      .right {
        width: 50%;
        padding: 16px 10px 16px 24px;
        display: flex;
        align-items: center;
      }
    }
  }

  .controller {
    @media only screen and (min-width: 800px) {
      width: 300px;
      margin: auto;
    }
  }
}
