.wrapper {
  padding: 12px 0 0 0;
  height: calc(var(--app-height) - 33px - 78px);
  overflow: hidden;

  .content {
    display: flex;
    gap: 6px;
    flex-direction: column;
    align-items: center;
  }

  .freezeNow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 30px;
    border-radius: 66px;
    margin-top: 24px;
    height: 70px;
    box-sizing: border-box;
    background: var(--sub-bg-color);
    border: 1px solid rgba(0, 174, 239, 0.4);
  }

  .historyWrapper {
    margin-top: 24px;

    .historyList {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: calc(var(--app-height) - 300px);
      overflow: auto;
    }
  }
}
