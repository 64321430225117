.tabWrapper {
  position: relative;

  .searchWrapper {
    margin: 0 0 16px 0;
  }

  .clubNearYouTitle {
    font-weight: 700;
    font-size: var(--font-xxl);
    margin-top: 12px;
    color: var(--black-color);
  }

  .clubCardList {
    margin-top: 12px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    height: calc(var(--app-height) - 245px);
    overflow: auto;

    .clubCard {
      width: 100%;
    }

    @media only screen and (min-width: 800px) {
      flex-direction: row;
      flex-wrap: wrap;

      .clubCard {
        width: calc(50% - 6px);
      }
    }
  }
}
