.container {
  padding: 0 24px;

  .infoWrapper {
    padding-top: 40px;
    padding-right: 30px;

    .ptInfo {
      position: relative;
      background: var(--white-text-color);
      padding: 20px;
      border: 1px solid var(--primary-text-color);
      border-radius: 18px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

      .item {
        display: flex;
        align-items: center;
        gap: 15px;
        width: 60%;
        height: 25px;

        .icon {
          width: 25px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .image {
      position: absolute;
      width: 45%;
      height: 165px;
      border-radius: 18px;
      overflow: hidden;
      right: -30px;
      top: -20px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: var(--primary-bg-color);
    }
  }

  .planList {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
