.popupWrapper {
  .blockInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 180px;
    margin: auto;

    .title1 {
      color: var(--primary-color);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }

    .title2 {
      color: var(--primary-text-color);
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 900;
      text-transform: uppercase;
      margin-top: -20px;
    }

    .title3 {
      color: var(--primary-color);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      text-transform: uppercase;
      white-space: pre-wrap;
    }
  }

  .controller {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
  }
}
