.wrapper {
  padding: 12px;

  .cameraWrapper {
    margin-top: 24px;
    width: 100%;
    height: auto;
    border-radius: 30px;
    overflow: hidden;
    background: #d9d9d9;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    video {
      border-radius: 30px;
      overflow: hidden;
    }

    .spinner {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }

    .cameraSwitch {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }

  .cardInfo {
    margin-top: 12px;

    .content {
      .wrapperInfo {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 12px;

        .formItem {
          width: 45%;
        }
      }

      .formItem {
        padding: 12px 0;
        display: flex;
        flex-direction: column;
        gap: 6px;

        input {
          font-size: var(--font-sm);
          color: var(--color-black);
        }
      }

      .divider {
        margin: 0;
        border-color: #d8d8d8;
      }
    }
  }

  .buttonWrapper {
    width: calc(100% - 48px);
    position: fixed;
    bottom: 24px;
    right: 50%;
    transform: translateX(50%);
    text-align: center;
    display: flex;
    gap: 16px;
    padding: 0 24px;
  }
}
