.cardWrapper {
  border-radius: 20px;
  border: 1px solid var(--primary-text-color);
  padding: 16px 24px;
  background: var(--white-text-color);
  display: flex;
  flex-direction: column;
  gap: 6px;

  .icon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }

  .time {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cardInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
