.container {
  .headerWrapper {
    height: 400px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .mask {
      background: linear-gradient(180deg, rgba(242, 250, 252, 1) 1%, rgba(18, 18, 18, 0.2) 20%);
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .overlay {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: center;
      background-image: url('../../../public/images/background/bg15.jpg');
    }

    .content {
      position: absolute;
      width: calc(100% - 48px);
      text-align: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      gap: var(--space-medium);
      bottom: 50px;

      .title {
        color: var(--white-text-color);
        font-size: 48px;
        text-transform: uppercase;
        font-weight: 900;
        font-style: italic;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: var(--primary-text-color);
        transform: rotate(-7.093deg);
        text-shadow: 4px 5px var(--primary-text-color);
        white-space: pre-wrap;
        text-align: center;
        line-height: 55px;
      }
    }
  }

  .features {
    margin-top: 0px;

    .ptSession {
      padding: 0 24px;
      margin-bottom: 24px;
    }

    .listAction {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 70px;
        gap: 3px;
        background: var(--white-text-color);
        border-radius: 15px;
        box-shadow: 0px 4px 17.1px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .content {
    margin-top: 30px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .todayClass {
    margin-top: 30px;
  }

  .discover {
    margin-top: 30px;
    padding: 0 24px;
  }
}
