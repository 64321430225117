.wrapper {
  * {
    font-weight: var(--font-medium);
    font-size: var(--font-md);
    position: relative;
    bottom: 3px;
  }

  padding: 8px 16px;
  height: 44px;
  border-radius: 50px;
  overflow: hidden;

  &.solid {
    color: var(--white-text-color);
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  &.outline {
    color: var(--primary-color);
    background: var(--white-text-color);
    border: 1px solid var(--primary-color);
  }

  &.highLight {
    background: var(--high-light-color);
    color: var(--white-text-color);
    border: none;
  }
}
