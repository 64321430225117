.cardWrapper {
  border-radius: 20px;
  padding: 20px;
  background-color: rgba(0, 174, 239, 0.1);
  border: 1px solid var(--primary-color);
  height: 140px;
  box-sizing: border-box;

  &.active {
    background: var(--primary-color);
  }

  .info,
  .title,
  .time {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .infoClub {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info {
    margin-bottom: 8px;
  }

  .infoClub {
    margin-bottom: 16px;
  }

  .time {
    margin-top: 8px;
  }
}
