.cardWrapper {
  border-radius: 20px;
  background: var(--white-text-color);
  padding: 12px 24px;
  border: 1px solid var(--primary-text-color);
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--sub-high-light-color);
  }

  .divider {
    width: 100%;
    height: 2px;
    background: var(--sub-high-light-color);
  }

  .codeBlock {
    display: flex;
    align-items: center;
    gap: 12px;

    .code {
      padding: 2px 14px;
      border-radius: 20px;
      background: var(--primary-text-color);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      p {
        font-size: var(--font-sm);
        color: var(--white-text-color);
        height: 24px;
        white-space: nowrap;
      }
    }
  }
}
