.wrapper {
  min-height: var(--app-height);

  .content {
    padding: 12px;

    .title {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    .moreInfo {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .block {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }

    .divider {
      margin: 24px 0;
    }

    .term {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;

      .block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;

        p {
          text-align: center;
          line-height: 16px;
        }
      }
    }
  }
}
